//调用app方法
module.exports = {
  // 判断当前是ios还是android
  isIos() {
    let u = navigator.userAgent;
    let ua = u.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      window.flutter_inappwebview.callHandler("upload", 1);
      // ios 系统
      // window.webkit.messageHandlers.upload.postMessage("1"); //ios
    } else {
      // android 系统
      window.flutter_inappwebview.callHandler("upload", 1);
    }
  },
  refreshToken() {
    window.flutter_inappwebview.callHandler("refreshToken");
  },
};
