<template>
  <div class="download-box">
    <div class="download-content">
      <div class="todownload">
        <img v-if="dataObj.logo" :src="dataObj.logo" alt />
        <p>{{dataObj.appName}}</p>
      </div>
      <div class="download-app">
        <div class="software-list">
          <!-- 苹果版本下载 -->
          <div class="software" v-if="dataObj.appStoreUrl">
            <img src="../assets/app-store.webp" alt />
            <span></span>
            <a
              :href="dataObj.appStoreUrl"
            >{{ isTimezoneOffset?'苹果版本下载':'Go to the Apple Store to download' }}</a>
          </div>
          <!-- 谷歌版本下载 -->
          <div class="software" v-if="!isTimezoneOffset&&dataObj.googlePlayUrl">
            <img :src="require(`../assets/google-play.png`)" alt />
            <span></span>
            <a
              :href="dataObj.googlePlayUrl"
            >{{isTimezoneOffset?'谷歌版本下载':'Go to Google Play to download'}}</a>
          </div>
          <!-- 安卓版本下载 -->
          <div class="software" v-if="isTimezoneOffset&&dataObj.chinaAppUrl">
            <img :src="require(`../assets/android-inland.png`)" alt />
            <span></span>
            <a
              :href="dataObj.chinaAppUrl"
            >{{isTimezoneOffset?'安卓版本下载':'Download the universal version for Android phones'}}</a>
          </div>
          <!-- 华为版本下载 -->
          <div class="software" v-if="!isTimezoneOffset&&dataObj.huaweiStoreUrl">
            <img :src="require(`../assets/huawei.png`)" alt />
            <span></span>
            <a
              :href="dataObj.huaweiStoreUrl"
            >{{isTimezoneOffset?'华为版本下载':'Go to the Huawei AppGallery to download'}}</a>
          </div>
        </div>
      </div>
      <div class="load" v-if="loading">
        <van-loading type="spinner" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getMarkingDetail } from "@/api/app.js";
export default {
  data() {
    return {
      key: "",
      loading: false,
      dataObj: {},
      appStoreUrl: "",
      googlePlayUrl: "",
      isTimezoneOffset: false, //是否在东8区
      newHaiweiUrl: "https://reefitsaat.cheyfit.com/#/code/IHyMej",
    };
  },
  methods: {
    //  新的方法
    toDownload() {
      var u = navigator.userAgent;
      var ua = u.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        // iOS 系统 ->  跳AppStore下载地址
        this.getMarkingDetail(this.key, (res) => {
          res.appStoreUrl && (window.location.href = res.appStoreUrl);
        });
      } else if (/android/.test(ua)) {
        // 安卓系统 -> 跳安卓端下载地址
        this.getMarkingDetail(this.key, (res) => {
          //如果是安卓系统在东8区不需要自动跳转
          //如果是华为或者东八区不需要自动跳转
          //暂时注释自动跳转
          // if (this.isTimezoneOffset || /huawei/.test(ua)) {
          //   return;
          // } else {
          //   window.location.href = res.googlePlayUrl;
          // }
        });
      } else if (this.IsPC()) {
        // PC端
        this.getMarkingDetail(this.key, (res) => {
          console.log("PC端");
          console.log(res);
        });
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    // 获取跳转地址
    getMarkingDetail(marking, callback) {
      this.loading = true;
      let data = {
        marking,
      };
      getMarkingDetail(data).then((response) => {
        this.loading = false;
        if (!response.data) {
          this.$toast.fail("暂无数据跳转");
          return;
        }
        this.dataObj = response.data;
        callback && callback(response.data);
      });
    },
  },
  mounted() {
    this.key = this.$route.params.key;

    let currentUrl = window.location.href;

    if (
      currentUrl.indexOf("apps.havitlife.com.cn") != -1 &&
      currentUrl.indexOf("IHyMej") != -1
    ) {
      window.location.href = this.newHaiweiUrl;
    }

    this.toDownload();

    this.isTimezoneOffset = new Date().getTimezoneOffset() == -480; // 获取当前时区,在东8区会显示-480
  },
};
</script>
  
  <style lang="scss" scoped>
.load {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  color: #3f3f3f;
  font-size: 60px;
}
.todownload {
  // height: calc(100vh);
  background-color: #fff;
  text-align: center;
  img {
    margin-top: 100px;
    width: 200px;
  }
}

// @media screen and (min-width: 750px) {

.download-box {
  display: flex;
  justify-content: center;
  // align-items: center;
}
.download-content {
  padding-top: 180px;
  width: 750px;
  .todownload {
    img {
      width: 190px;
    }
    p {
      text-align: center;
      font-size: 45px;
      margin: 20px 0;
      font-weight: bold;
    }
  }
  .download-app {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    .software-list {
      display: flex;
      justify-content: start;
      flex-direction: column;
      .software {
        display: flex;

        align-items: center;
        margin: 20px;

        img {
          width: 40px;
          border: 1px solid #f2f2f2;
          border-radius: 10px;
        }
        span {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 20px;
          color: #3f3f3f;
        }
        a {
          padding-left: 15px;
          text-align: center;
          text-decoration: underline;
          font-size: 30px;
          // color: #;
        }
      }
    }
  }
}
// }
</style>