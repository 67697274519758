import axios from "axios";
// import { MessageBox, Message } from "element-ui";
// import store from "@/store";
import { getToken, setToken } from "@/utils/auth";
import { refreshToken } from "@/utils/callAppMethod";

// console.log(process.env);
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    //先注释
    // if (store.getters.token) {
    // let each request carry token
    // please modify it according to the actual situation

    console.log("-------------是否取到了 getToken()-------------------");
    console.log(getToken());
    //没有token的时候设置token
    if (!getToken()) {
      // 临时
      let getToken =
        "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxNjM0NDYwMzY5OTc1Mzc3OTIyIiwibmJmIjoxNjc4NTIwNjkzLCJ0b2tlbkRhdGEiOiJ5TGcwQmZjdUhNNlYwUUpRWGFZeEhFUlVGZjhLcmJrWmxDczVIb0NRZUR0SW12blB4bHNEcXFvRGt1SFlXUUE2TVpWaE5xTFh2d2tCTTRtSDlWeGtrbWJmQ29mMUNrUUordnVzTDhQYlQweFJ6dDIxSkJQTHVFSkFHc3dJc0tLRyIsImlzcyI6IkNyZWVrIFdlYXJhYmxlIiwiZXhwIjoxNjc4NTI3ODkzLCJpYXQiOjE2Nzg1MjA2OTMsImp0aSI6IjE2MzQ0NjAzNjk5NzUzNzc5MjIifQ.jRC4ZqRXj-5RQPS3kZ7TSr6NADuk36YgaMfKt0tHPbXsbK3gZgqvj1giuLfuzUnl7Uyjqx5c3Ry6YlNvl2LuVbPIc7LR3y1396y09y6AXRSd8HOOJHEDy-EPSkNZzEZc2AUoBb-1r713619FQc23YAVXVvuTBhFTVX-Ntb3Ct-QIXGj5TWYrVAL_t9D2nDTj8tGkn5K6uJNCftJgp9HMrFmrQTmnnkbrEWox2LiwmShpxGbZ_AZJ1ss7BBvJbqcVybrUdk81oN-go7-lA5n6jbtotZem_Zv5t7MGEubpqCV_b_lhRtQlwOzSKFmhuNVRQpoYCIISxbJa7GXDPZ2Kkg";
      config.headers["Authorization"] = getToken;
    } else {
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log(response.config);
    const config = response.config;
    const res = response.data;

    // if the custom code is not 200, it is judged as an error.
    if (res.code !== 200) {
      // Message({
      //   message: res.message || "Error",
      //   type: "error",
      //   duration: 5 * 1000,
      // });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 50008 ||
        res.code === 50012 ||
        res.code === 50014 ||
        res.code === 502 ||
        res.code === 503 ||
        res.code === 504
      ) {
        //1. 通知app去刷新token
        refreshToken();
        //2.app回调JS方法
        const delay = new Promise((resolve) => {
          window.refreshPage = () => {
            config.headers["Authorization"] = getToken();
            resolve();
          };
        });
        return delay.then(function () {
          return service(config);
        });
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
