import request from "@/utils/request";

// 获取二维码跳转地址
export function getMarkingDetail(params) {
  return request({
    url: "service-manager/v1/app/info/markingDetail",
    method: "get",
    params,
  });
}
